@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

/* Moves sidebar background mask far beneath the view to stop it blocking additional overlays */
.p-component-overlay.p-sidebar-mask {
  /* z-index: 100000000000 !important; */
  opacity: 0;
}

@media (min-width: 1025px) {
  .layout-container.menu-layout-static .layout-main {
    margin-left: 250px;
  }
}

@media (max-width: 1024px) {
  .layout-container.menu-layout-static .layout-menu {
    margin-left: -100%;
  }
}

@media (min-width: 1025px) {
  .layout-container.menu-layout-static.layout-menu-static-inactive
    .layout-menu {
    margin-left: -250px;
  }
}

@media (min-width: 1025px) {
  .layout-container .layout-menu .ui-scrollpanel .menu-scroll-content {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .layout-container .layout-menu .ui-scrollpanel .menu-scroll-content {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  body .p-sidebar {
    width: 100% !important;
  }
}

/* @media (max-width: 1024px) {
  .layout-container.menu-layout-horizontal .layout-menu {
    overflow: visible;
    position: fixed;
    width: 100%;
    top: 75px;
    height: auto;
  }
} */

.layout-container.menu-layout-horizontal
  .layout-menu
  ul.ultima-menu.ultima-main-menu {
  background-image: linear-gradient(90deg, #00ac86, #59bc62);
}

/* Overwrites menu color */
.layout-container .layout-menu .p-scrollpanel {
  background-color: #00ac86;
}
.layout-container {
  overflow: hidden;
}
/* Overwrites topbar color */
.topbar-left {
  background-color: #00ac86 !important;
}
.topbar-right {
  /* background-color: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 10%,
    #00ac86 60%
  ); */
  position: unset !important;
  background-color: #00ac86 !important;
}

/* Allow sidebar to extend indefinitely */
.p-sidebar-top {
  height: auto !important;
}

/* Moves dialog above view and fixes transparency issues*/
.p-dialog-wrapper {
  background-color: rgba(66, 66, 66, 0.8) !important;
  opacity: 1 !important;
}

/* Custom overlay */
.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #424242;
  opacity: 0.8;
  z-index: 2;
}
.component-overlay {
  position: relative;
  background-color: #424242;
  opacity: 0.8;
  z-index: 1;
}
.flow-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #424242;
  opacity: 0.4;
  z-index: 1;
}

.profile-card {
  /* border: 1px solid #00ac86; */
  border-radius: 5px;
  margin-top: 5px;
}

body .p-checkbox .p-checkbox-box:before {
  width: 0px !important;
  height: 0px !important;
}

/* Custom label stylings */
.bold {
  font-weight: 900;
}

.custom-label {
  font-size: 12px;
  color: #00ac86;
}

.custom-form-label {
  font-size: 14px;
  color: #00ac86;
}

.input-label {
  font-size: 14px;
  color: #000000;
  width: 100%;
  display: block;
  white-space: normal;

  text-overflow: ellipsis;
}
.input-label-large {
  font-size: 1rem;
  color: #000000;
  width: 100%;
  display: block;
  white-space: nowrap;

  text-overflow: ellipsis;
}
.disabled-label {
  font-size: 14px;
  color: #000000;
  width: 100%;
  display: block;
  white-space: normal;
  background-color: #f7f7f7;
  text-overflow: ellipsis;
}
.header-label {
  font-size: 18px;
}

/* Stylings to keep UI elements consistent */
.md-inputfield.form-field {
  margin-top: 22px;
  /* background-color: #f7f7f7; */
}

body .md-inputfield input:focus ~ label,
body .md-inputfield input.p-filled ~ label,
body .md-inputfield .p-inputwrapper-focus ~ label,
body .md-inputfield .p-inputwrapper-filled ~ label {
  font-size: 12px;
  color: #00ac86;
}

body .md-inputfield label {
  font-size: 12px;
  color: #00ac86;
  left: 0px;
}

body
  .p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container
  .p-inputtext {
  color: black;
}

.p-multiselect-panel {
  z-index: 9999999999 !important;
}

.p-inputtext {
  width: 100%;
  font-size: 1.5rem;
}
body .p-inputtext:disabled {
  border-bottom: 0px dotted !important;
}

body .p-inputtext {
  width: 100%;
  background-color: #f7f7f7;
  border-radius: 0px;
  font-size: 1rem;
  background-image: linear-gradient(to bottom, #00ac86, #00ac86),
    linear-gradient(to bottom, transparent, transparent);
}

.p-dropdown-panel {
  z-index: 100000000000 !important;
}

.p-overlaypanel {
  z-index: 100000000000 !important;
}

body .p-datepicker {
  z-index: 100000000000 !important;
}

body .p-dropdown {
  margin-top: 1px;
  width: 100% !important;
  min-width: auto !important;
  background-color: #f7f7f7;
  background-image: linear-gradient(to bottom, #00ac86, #00ac86),
    linear-gradient(to bottom, transparent, transparent);
}
.dropdownDatatable {
  z-index: 1000000000 !important;
  margin-top: 1px;
  width: 100% !important;
  min-width: auto !important;
  background-color: #f7f7f7;
  background-image: linear-gradient(to bottom, #00ac86, #00ac86),
    linear-gradient(to bottom, transparent, transparent);
}
textarea {
  background-color: #f7f7f7;
}
body .p-editor-container {
  border: 1px solid #00ac86;
  box-shadow: 0 0 0px #666;
  border-radius: 5px;
}
div .p-sidebar {
  border-radius: 10px;
}
/* Paginator fix */
.p-paginator .p-dropdown {
  width: auto !important;
}

.p-multiselect {
  margin-top: 2px;
  width: 100% !important;
}

.p-calendar {
  margin-top: 7px;
  width: 100%;
}

/* Stylings to make button icons bigger by default */
.p-button-icon-left {
  font-size: 24px;
  margin: 0px;
}

.p-button-icon-right {
  font-size: 24px;
  margin: 0px;
}

/* Increase opacity of disabled UI by default */
body .p-widget:disabled,
body .p-state-disabled {
  opacity: 0.8;
}

/* Styling so pages outside of the menu template fit fullscreen */
body > #root > div > div {
  min-height: 100vh;
  max-height: 100%;
}

/* Fullscreen background for login flow */
.login-page {
  background: url("../public/assets/layout/images/council/manufacturing.png")
    top left no-repeat #f7f7f7;
  background-size: 100% 100%;
  min-height: 100vh;
}

.login-page#BUYERSUPPLIER {
  background: url("../public/assets/layout/images/council/background-buy.jpg")
    top left no-repeat #f7f7f7;
  background-size: 100% 100%;
  min-height: 101vh;
}
.login-page#COUNCIL {
  background: url("../public/assets/layout/images/council/background-council.jpg")
    top left no-repeat #f7f7f7;
  background-size: 100% 100%;
  min-height: 101vh;
}

.login-page#login-buy {
  background: url("../public/assets/layout/images/council/background-buy.jpg")
    top left no-repeat #f7f7f7;
  background-size: 100% 100%;
  min-height: 101vh;
}

.login-page#login-supply {
  background: url("../public/assets/layout/images/council/background-supply.jpg")
    top left no-repeat #f7f7f7;
  background-size: 100% 100%;
  min-height: 101vh;
}

.login-page#login-council {
  background: url("../public/assets/layout/images/council/background-council.jpg")
    top left no-repeat #f7f7f7;
  background-size: 100% 100%;
  min-height: 101vh;
}

.login-page#login-ppe {
  background: url("../public/assets/layout/images/council/background-ppe.jpg")
    top left no-repeat #f7f7f7;
  background-size: 100% 100%;
  min-height: 101vh;
}
.visualiser {
  background: linear-gradient(to right, rgb(37, 37, 37), #4e4e4e);
}

.login-panel .p-colrid .p-col-12 {
  padding-top: 0;
}

.login-panel.card {
  margin-top: 100px;
}

@media (max-width: 640px) {
  .login-panel.card {
    margin-top: 10px;
    padding-bottom: 0;
  }
}

@media (max-width: 640px) {
  .login-panel .p-colrid .p-col-12 .p-button {
    margin-top: 0;
  }
}

body {
  font-size: 12px;
}

/* Breadcrumb style */
body .p-breadcrumb {
  background-color: #f7f7f7 !important;
  color: black;
}

body .p-breadcrumb .p-breadcrumb-chevron {
  color: black;
}

.p-breadcrumb ul li :nth-child(1) {
  color: black;
}

/* Spin property to be applied via className */
/* Used to rotate icons in buttons */
.pi-spin {
  animation-delay: 0;
  animation-duration: 1000ms;
  animation-name: "spin";
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/* Margin property to be applied via className */
/* Used to space the buttons inside elements */
.mrgn {
  margin: 1px;
}

/* Color properties to be applied via className */
.blk {
  color: white !important;
  background-color: black !important;
}

/* Float properties to be applied via className */
.flt-r {
  float: right;
}

.flt-l {
  float: left;
}

.productDetails {
  opacity: 1;
  z-index: 10000000000 !important;
}

/* Dialog properties to be applied via className */
/* .dlg {
  color: #ffffff;
  background-color: #00ac86 !important;
  opacity: 1;
} */
body .p-component-overlay {
  background-color: #424242;
  opacity: 0.98;
  filter: alpha(opacity=80);
}
/* Sidebar properties to be applied via className */
.sdbr {
  width: 80vw !important;
  margin-left: 20vw;
  position: relative;
  z-index: 1000000000;
}
.p-sidebar-full {
  z-index: 1000000000;
}

.bpm {
  height: 100%;
  width: 100%;
  padding: 10px;
  padding-left: 15px;
  font-size: 12px;
}
.scrl {
  height: 85vh;
  border-color: transparent !important;
  padding-bottom: 20px;
  overflow: auto;
  padding: 10px;
}

.scrl_full {
  border-color: transparent !important;
  padding-bottom: 20px;
  overflow: auto;
  padding: 10px;
  height: 95vh;
}

.menuScrl .p-scrollpanel-wrapper {
  border-right: 0px solid transparent;
  padding: 0px;
}
body .p-scrollpanel-bar-y {
  background-color: transparent;
  width: 9px;
  opacity: 0;
  margin-top: 10px;
}
body .p-scrollpanel-bar-y:hover {
  background-color: transparent;
  width: 9px;
  opacity: 0;
  margin-top: 10px;
}
.p-scrollpanel {
  border: 0px solid black;
  background-color: transparent;
}
.p-scrollpanel-wrapper {
  border-right: 0px solid transparent;
  background-color: white;
}
.p-scrollpanel-content {
  border-right: 0px solid transparent;

  border: 0px solid black;
}

/* Absolute element for sidebar buttons */
.sidebar-footer {
  /* border-top: 1px solid; */
  padding: 10px;
}

/* Styling for the sidebar close button */
.p-sidebar .p-sidebar-close {
  background-color: #00ac86;
  color: white !important;
  border: 1px solid #00ac86;
  border-radius: 5px;
  height: 2.143rem;
  padding: 0 0.5rem;
}
.p-sidebar .p-sidebar-close:not(.p-highlight):hover {
  background-color: #00ac86;
  color: white;
  box-shadow: 0 0 15px red;
  height: 2.143rem;
  padding: 0 0.5rem;
}

/* Enforce round buttons */
.p-button.p-button-icon-only {
  width: 2.143em !important;
  height: 2.143em !important;
}

/* Round card, blue border */
.card-round {
  border-radius: 2px;
  border: 0px solid #00ac86;
  background-color: white;
  box-shadow: 0 0 2px #00ac86;
}
.card-shade {
  border-radius: 10px;
  border: 0px solid #00ac86;
  background-color: white;
  box-shadow: 0 0 5px #00ac86;
}
.card-shade:hover {
  background: #0ea5db;
  color: black;
  -webkit-filter: invert(100%);
}
.card-operator {
  border-radius: 10px;
  border: 0px solid #00ac86;
  background-color: white;
  box-shadow: 0 0 0px #00ac86;
}

body .p-paginator {
  background-color: transparent;
  padding: 0.625em 1em;
  border-radius: 0px;
  border: 1px solid transparent;
  color: black;
}
body .p-paginator .p-paginator-pages .p-paginator-page {
  box-sizing: border-box;
  color: black;
}
body .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background-color: #085dc4;
  color: white;
}
body .p-paginator .p-paginator-first {
  color: #00ac86;
}
body .p-paginator .p-paginator-prev {
  color: #00ac86;
}
body .p-paginator .p-paginator-last {
  color: #00ac86;
}
body .p-paginator .p-paginator-next {
  color: #00ac86;
}
.p-dialog-titlebar {
  background-color: #00ac86;
  color: white;
}
.error {
  border: 1px solid red;
}

.square {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  background-color: #2980b9;
}
body .p-inputtext.p-state-error {
  background-image: linear-gradient(to bottom, #e62a10, #e62a10),
    linear-gradient(to bottom, #e62a10, #e62a10);
}
body .p-inputtext.p-state-reqpred {
  background-image: linear-gradient(to bottom, #00ac86, #00ac86),
    linear-gradient(to bottom, #00ac86, #00ac86);
}

.p-orderlist .p-button.p-orderlist-button-move-up {
  display: none;
}

body .p-orderlist.p-orderlist-responsive .p-orderlist-controls {
  display: none;
}
#Container {
  width: 100%;
  height: 100%;
  position: relative;
}

.bottom-bar {
  width: 100%;
  height: 10%;
  background-color: #000000;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0;
  border: 0 none;
  box-shadow: 0 0 3px #666;
}
.p-scrollpanel {
  background-color: red;
  border: 0px solid black;
}
.p-scrollpanel-content {
  border-right: 0px solid transparent;

  border: 0px solid black;
}
.p-scrollpanel-bar {
  background-color: #1976d2;
  opacity: 1;
  transition: background-color 0.3s;
}
.p-scrollpanel-bar:hover {
  background-color: #135ba1;
}

.p-icon-reorder {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 3em;
  height: 3em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}
.p-icon-reorder:before {
  content: "reorder";
}

.srd-full-canvas {
  height: 85vh;
  width: 100%;
}
.srd-drag-canvas {
  height: 80vh;
  width: 100%;
}
.srd-half-canvas {
  height: 40vh;
  width: 100%;
}

body .p-paginator {
  background-color: transparent;
  padding: 0.625em 1em;
  border-radius: 0px;
  border: 1px solid transparent;
  color: black;
}

body .p-datatable {
  border: 0px solid #00ac86;
  box-shadow: 0 0 0px #666;
  border-radius: 0px;
}

body .p-datatable-wrapper {
  border: 1px solid #d8d8d8;
}

body .p-datatable .p-datatable-tbody {
  border: 1px solid #d8d8d8;
  background: white;
}

body .p-datatable .p-datatable-header,
body .p-datatable .p-datatable-footer {
  background-color: #dae4f1;
  /* background: linear-gradient(
    90deg,
    rgba(249, 107, 3, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  ); */
  color: #ffffff;
  border: 0px solid #00ac86;
  padding: 0.714em 1em;
  text-align: center;
  font-weight: normal;
}

body .p-datatable .p-datatable-tbody > tr {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-top: 0px;
  border-bottom: 0px;
}

.noShade {
  border: 1px solid transparent;
  box-shadow: 0 0 0px #666;
}
body .p-dataview {
  border: 1px solid transparent;
  box-shadow: 0 0 20px #666;
}
body .p-sidebar {
  z-index: 1000000000;
}
a.p-sidebar-close {
  background-color: #00ac86;
  color: white;
  border: 1px solid #00ac86;
  padding: 20px;
  border-radius: 5px;
  float: left;
}
/* a.p-sidebar-close {
  display: none;
} */
body .p-dialog .p-dialog-titlebar {
  background-color: #00ac86;
  color: white;
  padding: 0.625em 1em;
}
body .p-dialog .p-dialog-footer {
  text-align: right;
  border: 0 none;
  border-top: 1px solid #dbdbdb;
  background-color: #00ac86;
  color: white;
  padding: 0.625em 1em;
}
body .p-datatable .p-paginator {
  padding: 0.625em 0.1em;
}
body .p-datatable .p-datatable-loading {
  opacity: 0.3;
  filter: alpha(opacity=30);
  background-color: white;
}
.p-dialog-title-dialog {
  background-image: none;
  background-color: #00ac86;
}

.p-dialog.p-dialog-footer {
  background-image: none;
  background-color: #00ac86;
}
/*
  UI G 12
*/

div.p-col-12.card-round {
  border-radius: 5px;
  border: 1px solid #00ac86;
  background-color: white;
}
div.p-col-12.title-round {
  border-radius: 5px;
  background-color: #00ac86;
}

div.p-col-12.row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
}
div.p-col-12.card-cell {
  border-radius: 5px;
  border: 1px solid #00ac86;
  background-color: white;
  box-shadow: 0 0 3px #666;
  flex: 1;
  margin-right: 10px;
  padding: 15px;
}
div.p-col-12.content-table {
  display: table;
  border-collapse: separate;
  border-spacing: 30px 0;
}

/*
  UI G 10
*/
div.p-col-10.card-round {
  border-radius: 5px;
  border: 1px solid #00ac86;
  background-color: white;
  padding: 0px;
}
div.p-col-10.title-round {
  border-radius: 5px;
  background-color: #00ac86;
}

div.p-col-10.row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
}
div.p-col-10.card-cell {
  border-radius: 5px;
  border: 1px solid #00ac86;
  background-color: white;
  box-shadow: 0 0 3px #666;
  flex: 1;
  margin-right: 10px;
  padding: 15px;
}
div.p-col-10.content-table {
  display: table;
  border-collapse: separate;
  border-spacing: 30px 0;
}
/*
  UI G 8
*/
div.p-col-8.card-round {
  border-radius: 5px;
  border: 1px solid #00ac86;
  background-color: white;
}
div.p-col-8.title-round {
  border-radius: 5px;
  background-color: #00ac86;
}
div.p-col-8.row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}
div.p-col-8.card-cell {
  border-radius: 5px;
  border: 1px solid #00ac86;
  background-color: white;
  box-shadow: 0 0 3px #666;
  flex: 1;
  margin-right: 10px;
  padding: 15px;
}
div.p-col-8.content-table {
  display: table;
  border-collapse: separate;
  border-spacing: 30px 0;
}
/*
  UI G 6
*/
div.p-col-6.card-round {
  border-radius: 5px;
  border: 1px solid #00ac86;
  background-color: white;
}
div.p-col-6.title-round {
  border-radius: 5px;
  background-color: #00ac86;
}
div.p-col-6.row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}
div.p-col-6.card-cell {
  border-radius: 5px;
  border: 1px solid #00ac86;
  background-color: white;
  box-shadow: 0 0 3px #666;
  flex: 1;
  margin-right: 10px;
  padding: 15px;
}
div.p-col-6.content-table {
  display: table;
  border-collapse: separate;
  border-spacing: 30px 0;
}
/*
  UI G 4
*/
div.p-col-4.card-round {
  border-radius: 5px;
  border: 1px solid #00ac86;
  background-color: white;
}
div.p-col-4.title-round {
  border-radius: 5px;
  background-color: #00ac86;
}
div.p-col-4.row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
}
div.p-col-4.card-cell {
  border-radius: 5px;
  border: 1px solid #00ac86;
  background-color: white;
  box-shadow: 0 0 3px #666;
  flex: 1;
  margin-right: 10px;
  padding: 15px;
}
div.p-col-4.cell {
  background-color: transparent;
  flex: 1;
  margin-right: 10px;
  padding: 15px;
}
div.p-col-4.content-table {
  display: table;
  border-collapse: separate;
  border-spacing: 30px 0;
}

/*
  UI G 3
*/
div.p-col-3.card-round {
  border-radius: 5px;
  border: 1px solid #00ac86;
  background-color: white;
}
div.p-col-3.title-round {
  border-radius: 5px;
  background-color: #00ac86;
}
div.p-col-3.row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
}
div.p-col-3.card-cell {
  border-radius: 5px;
  border: 1px solid #00ac86;
  background-color: white;
  box-shadow: 0 0 3px #666;
  flex: 1;
  margin-right: 10px;
  padding: 15px;
}
div.p-col-3.content-table {
  display: table;
  border-collapse: separate;
  border-spacing: 30px 0;
}

div.p-col-2.card-round {
  border-radius: 5px;
  border: 1px solid #00ac86;
  background-color: white;
}
div.p-col-2.title-round {
  border-radius: 5px;
  background-color: #00ac86;
}
div.p-col-2.row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
}
div.p-col-2.card-cell {
  border-radius: 5px;
  border: 1px solid #00ac86;
  background-color: white;
  box-shadow: 0 0 3px #666;
  flex: 1;
  margin-right: 10px;
  padding: 15px;
}
div.p-col-2.content-table {
  display: table;
  border-collapse: separate;
  border-spacing: 30px 0;
}

h2.title {
  color: #00ac86;
  text-align: center;
}
h3.input-name {
  color: #00ac86;
  text-align: center;
}
.c-modal-overflow {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll;
}
body .p-tabview.p-tabview-top > .p-tabview-nav > li {
  width: 22%;
}
body .p-tabview {
  border-color: transparent;
}
body .p-tabview-selected .p-state-active > li {
  background-color: white;
}

body .p-inputtextarea {
  border: firebrick;
}
.p-col-6.inputBorder {
  border: firebrick;
}

/* #pr_id_x_header_y
  Where x is the tabview and y is the tabpanel */
.p-steps.steps-custom {
  margin-bottom: 30px;
}

.p-steps.steps-custom .p-steps-item .p-menptem-link {
  height: 10px;
  padding: 0 1em;
  overflow: visible;
}

.p-steps.steps-custom .p-steps-item .p-steps-number {
  background-color: #0081c2;
  color: #ffffff;
  display: inline-block;
  width: 36px;
  border-radius: 50%;
  margin-top: -14px;
  margin-bottom: 10px;
}

.p-steps.steps-custom .p-steps-item .p-steps-title {
  color: #555555;
}
.company.p-organizationchart .p-organizationchart-node-content.p-input {
  padding: 0;
  border: 0 none;
}
.company.p-organizationchart .p-organizationchart-node-content.p-output {
  padding: 0;
  border: 0 none;
}
.company.p-organizationchart .p-organizationchart-node-content.p-station {
  padding: 0;
  border: 0 none;
}
.node-header,
.node-content {
  padding: 0.5em 0.7em;
}

.node-header {
  background-color: #495ebb;
  color: #ffffff;
}

.node-content {
  text-align: center;
  border: 1px solid #495ebb;
}

.node-content img {
  border-radius: 50%;
}

.department-cfo {
  background-color: #7247bc;
  color: #ffffff;
}

.department-coo {
  background-color: #a534b6;
  color: #ffffff;
}

.department-cto {
  background-color: #e9286f;
  color: #ffffff;
}

.p-organizationchart .p-highlight {
  background-color: orange;
}

.p-person .p-node-toggler {
  color: #495ebb !important;
}

.department-cto .p-node-toggler {
  color: #8a0a39 !important;
}
.overflow {
  overflow: hidden;
  display: flex;
}

.react-trello-lane {
  border: 0;
  background-color: grey;
  max-height: 75vh;
}
/* .react-trello-board {
  border: 0px;
  background-color: transparent;
  overflow-y: hidden;
}

.react-trello-card {
  border: 0;
  background-color: grey;
} */
*/ body .p-breadcrumb {
  padding: 0;
  border: none;
  align-content: center;
}
body .p-breadcrumb ul li a {
  color: black;
}
.p-breadcrumb-chevron {
  color: black;
  background-color: transparent;
}
body .p-highlight {
  background-color: transparent;
  color: #000000;
}
body .p-highlight .pi {
  color: black;
}
body .p-fileupload .p-fileupload-buttonbar {
  background-color: transparent;
  border-color: transparent;
}
body .p-fileupload .p-fileupload-content {
  border-color: transparent;
}
body .p-fileupload .p-fileupload-buttonbar .p-button {
  background-color: #00ac86;
}
.ui.comments {
  font-size: 1rem;
  height: 75vh;
  overflow: scroll;
}
body .p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-size: 1.25em;
  margin-left: -1px;
  margin-top: -1px;
  color: white;
  background-color: green;
}

.highlightHeading {
  background-color: #c8e6c9;
  color: #256029;
}
/* .smooth-dnd-container .vertical {
  overflow: auto;
  height: 78vh;
} */
svg:not(:root) {
  overflow: unset;
}

body
  .p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  a {
  color: white;
  background-color: green;
}
body
  .p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  a
  .p-accordion-toggle-icon {
  color: white;
  background-color: green;
}
.p-accordion-header-text {
  width: 100%;
}
body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight {
  color: white;
  background-color: green;
}
body .p-button:enabled:not(:focus):hover {
  color: white;
  box-shadow: 0 0 10px green;
}
body .trasparentButton .p-button:enabled:not(:focus):hover {
  color: white;
  box-shadow: 0 0 0px green;
}
.p-tree-container {
  width: 100%;
}
body .p-tree {
  width: 50vw;
}
body .p-tree .p-tree-container .p-treenode .p-treenode-content {
  width: 40vw;
}
body .p-treenode-label {
  width: inherit;
}

body .p-button:focus {
  outline: 0 none;
  box-shadow: 0 0 10px #666;
}
body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: black;
}
.diagram-container {
  background: #333333;
  width: 100%;
  height: 100%;
}
.diagram-container > svg {
  z-index: 1;
}

.custom-node {
  border: solid 2px gray;
  border-radius: 5px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}

.custom-node-color {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

.circle-port {
}

.circle-port:hover {
  background: mediumpurple;
  box-shadow: 0 0 10px #666;
}
body .p-overlaypanel .p-component {
  z-index: 10000000000;
}

.bpm-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: visible;
  border-radius: 5px;
}

.bpm-col {
  padding: 0.5em;
}

.bpm-col-fixed {
  padding: 0.5em;
}

.bpm-col-align-start {
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.bpm-col-1,
.bpm-col-2,
.bpm-col-3,
.bpm-col-4,
.bpm-col-5,
.bpm-col-6,
.bpm-col-7,
.bpm-col-8,
.bpm-col-9,
.bpm-col-10,
.bpm-col-11,
.bpm-col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 3px;
  background-color: transparent;
}

.bpm-col-1 {
  width: 8.3333%;
}

.bpm-col-2 {
  width: 16.6667%;
}

.bpm-col-3 {
  width: 25%;
}

.bpm-col-4 {
  width: 33.3333%;

  display: inline-block;
}

.bpm-col-5 {
  width: 41.6667%;
}

.bpm-col-6 {
  width: 50%;

  display: inline-block;
}

.bpm-col-7 {
  width: 58.3333%;
}

.bpm-col-8 {
  width: 66.6667%;

  display: inline;
}

.bpm-col-9 {
  width: 75%;
}

.bpm-col-10 {
  width: 83.3333%;
}

.bpm-col-11 {
  width: 91.6667%;
}

.bpm-col-12 {
  width: 100%;
}

/* Datascroller. */
.product-badge.status-notstarted {
  background: #c8e6c9;
  color: #256029;
}
.product-badge.status-complete {
  background: #ffcdd2;
  color: #c63737;
}
.product-badge.status-inprogress {
  background: #feedaf;
  color: #8a5340;
}
/* Datatable. */
.datatable.status-notstarted {
  background: #c63737;
  color: #ffffff;
}
.datatable.status-initiated {
  background: #c63737;
  color: #ffffff;
}
.datatable.status-inprogress {
  background: #e45120;
  color: #ffffff;
}
.datatable.status-in_progress {
  background: #e45120;
  color: #ffffff;
}
.datatable.status-complete {
  background: #138d08;
  color: #ffffff;
}
/* datatable row header */
.rowheader.type-labour {
  background: #ffac74;
  color: #5d5d5d;
}
.rowheader.type-material {
  background: #74daff;
  color: #5d5d5d;
}
/* .rowheader.type-overhead {
  background: #98f761;
  color: #5d5d5d;
} */
.rowheader.type-hire {
  background: #98f761;
  color: #5d5d5d;
}

/* Project Dashboard Stats */
.payments-bullet-today {
  color: #256029;
}
.payments-bullet-month {
  color: #81408a;
}

.retentionfee {
  color: #12bcc2;
}
.retentionfeedate {
  color: #25af43;
}

.invoice-bullet-due {
  color: #cc2a2a;
}
.invoice-bullet-overdue {
  color: #f5970a;
}

.pi-circle-on:before {
  content: "\e911";
}

/* 
.text-primary {
  color: rgb(0, 123, 255) !important;
} 
*/

.p-progressbar-value {
  background-color: #0991e0;
  border: #0991e0;
}

.p-progressbar {
  background-color: #0991e0;
  border: #0991e0;
}

.p-progressbar-determinate {
  background-color: #0991e0;
  border: #0991e0;
}
.p-fileupload-content .p-progressbar {
  background-color: transparent;
  position: inherit;
}
.dashboardbox {
  background: #dae4f1;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-size: 16;
  color: black;
  border-radius: 7px;
}

.dashboardcard-background {
  background: radial-gradient(
    circle,
    rgba(172, 222, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(172, 222, 255, 1) 100%
  );
  border-radius: 7px;
}

.dashboardbox-plain {
  background: #ffffff;
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: black;
  border-radius: 7px;
}

.dashboardbox-1 {
  background: linear-gradient(
    90deg,
    rgba(122, 35, 255, 1) 0%,
    rgba(175, 127, 247, 1) 100%
  );
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}
.dashboardbox-2 {
  background: linear-gradient(
    90deg,
    rgba(247, 190, 8, 1) 50%,
    rgba(253 246 62) 100%
  );
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}
.dashboardbox-3 {
  background: linear-gradient(90deg, rgb(2 193 28) 0%, rgb(113 220 127) 100%);
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}
.dashboardbox-4 {
  /* background: #eb2626; */
  background: linear-gradient(
    90deg,
    rgba(235, 38, 38, 1) 0%,
    rgba(255, 86, 86, 1) 100%
  );
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}
.dashboardbox-5 {
  /* background: #f9b873; */
  background: linear-gradient(
    90deg,
    rgba(255, 104, 0, 1) 0%,
    rgba(254, 167, 48, 1) 100%
  );
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}
.dashboardbox-6 {
  /* background: #19c8df;
   */
  background: linear-gradient(
    90deg,
    rgba(11, 1, 171, 1) 0%,
    rgba(17, 17, 238, 1) 18%,
    rgba(0, 212, 255, 1) 100%
  );
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}

/* DARK */
.dashboardbox-1-singledark {
  background: rgba(175, 127, 247, 1);
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}
.dashboardbox-2-singledark {
  background: rgba(247, 190, 8, 1);
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}
.dashboardbox-3-singledark {
  background: rgb(2 193 28);
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}
.dashboardbox-4-singledark {
  background: rgba(17, 17, 238, 1);
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}
.dashboardbox-5-singledark {
  background: rgba(254, 167, 48, 1);
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}
.dashboardbox-6-singledark {
  background: rgba(255, 86, 86, 1);
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}

/* MEDIUM */
.dashboardbox-1-singlemedium {
  background: rgba(192, 164, 235, 1);
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}
.dashboardbox-2-singlemedium {
  background: rgba(232, 197, 86, 1);
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}
.dashboardbox-3-singlemedium {
  background: rgb(102, 212, 117);
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}
.dashboardbox-4-singlemedium {
  background: rgb(61, 61, 255);
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}
.dashboardbox-5-singlemedium {
  background: rgb(253, 177, 71);
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}
.dashboardbox-6-singlemedium {
  background: rgba(255, 86, 86, 1);
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}

/* LIGHT */
.dashboardbox-1-singlelight {
  background: rgb(213, 199, 235);
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}
.dashboardbox-2-singlelight {
  background: rgb(235, 212, 138);
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}
.dashboardbox-3-singlelight {
  background: rgb(145, 217, 155);
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}

.dashboardbox-4-singlelight {
  background: rgb(77, 152, 250);
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}
.dashboardbox-5-singlelight {
  background: rgb(250, 185, 93);
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}
.dashboardbox-6-singlelight {
  background: rgb(255, 86, 86);
  font-family: "Robotolvetica Neue", sans-serif;
  font-size: 16;
  color: white;
  border-radius: 7px;
}

.stat-cards {
  font-size: 16px;
}

.italics {
  font-style: italic;
}

.heading {
  /* font-family: "varela-regular"; */
  font-family: "Robotolvetica Neue", sans-serif;
}
body .p-datatable .p-datatable-header .p-inputtext,
body .p-datatable .p-datatable-footer .p-inputtext {
  color: black;
}
body .p-button.secondary-btn:disabled {
  background-color: grey;
}
body .p-sidebar .p-button {
  width: auto;
  margin-right: 5px;
}
body .p-sidebar .p-button:disabled {
  width: auto;
  margin-right: 5px;
  background-color: grey;
}
body .p-button:disabled {
  background-color: grey;
}
.p-ripple.purple .p-ink {
  background: rgba(256, 39, 176, 0.3);
}

.toolbar-splitbutton {
  background-color: #00ac86;
}

.table-button {
  background-color: transparent !important;
  color: black !important;
}

.orange-table-button {
  background-color: #00ac86 !important;
  color: white !important;
}

body .p-progressbar .p-progressbar-value {
  height: 3em;
}

body .p-progressbar {
  height: 3em;
}

body .tabview-custom {
  vertical-align: middle;
}

body .tabview-custom .p-button {
  margin-right: 0.25rem;
}

body .tabview-custom .p-tabview {
  line-height: 1.5;
  margin: 0;
}

body .tabview-custom .p-tabview-nav {
  border: 0px;
  border-bottom: 1px solid #000000;
}

body .p-tabview-custom .p-tabview-nav > li {
  border-right: 0.5px #000000;
}

.googleChartCustom {
  border-radius: "7px";
  background-color: "#b5c9ff";
}

.p-contextmenu {
  position: inherit !important;
  width: 20%;
}

div .node {
  height: max-content;
  width: max-content;
  background-color: transparent;
  color: white;
  border: 2px solid black;
  border-radius: 5px;
  font-family: sans-serif;
  overflow: visible;
}
body .p-accordion .p-accordion-content {
  background-color: transparent;
}
.border-box {
  border: 2px solid black;
  border-radius: 5px;
}

.box-stretched {
  height: 100%;
}

.p-badge-circle {
  background-color: white;
  color: black;
  width: 10%;
}

.p-badge {
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  background-color: white;
  color: black;
}

.p-tag {
  display: inline-block;
  text-align: center;
  line-height: 1.5;
}

.p-tag.p-tag-rounded {
  border-radius: 10rem;
}

.p-overlay-badge {
  position: relative;
  display: inline-block;
}

.p-overlay-badge .p-badge {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(1em, -1em);
  margin: 0;
}

.button-demo .p-button {
  margin-right: 0.5rem;
}

.button-demo .p-buttonset .p-button {
  margin-right: 0;
}

.button-demo .sizes .button {
  margin-bottom: 0.5rem;
  display: block;
}

.button-demo .sizes .button:last-child {
  margin-bottom: 0;
}

.p-button .p-badge {
  padding-right: 0.2em;
  margin-left: 0.5rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  color: black;
  background-color: #ffffff;
  border-radius: 3px;
  font-size: 0.8em;
}

.p-disabled {
  opacity: 1 !important;
}

.p-autocomplete-item {
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  font-size: 1rem;
}
.a-reactPinField__input {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 1px solid gray;
  outline: none;
  text-align: center;
  margin: 10px;
}
.datatable-style-demo .highlight {
  background-color: #00ac86 !important;
  color: white;
}
body .p-togglebutton.p-highlight {
  background-color: green;
}

body .p-dropdown-panel .p-dropdown-filter-container input {
  color: black;
}
.custom-tabview {
  border: 1px solid #f15a24;
  padding-bottom: 25px !important;
}
body .p-dropdown-clear-icon {
  position: sticky;
}
.layout-container .topbar .topbar-right .topbar-items li button {
  float: left;
}
.colorbox {
  border-radius: 5px;
}
.colorbox.colorbox-66 {
  background-color: red;
}
.colorbox.colorbox-66 div:first-child {
  background-color: rgb(214, 63, 63);
}
.colorbox.colorbox-orange {
  /* background-color: orange; */
  background: linear-gradient(
    180deg,
    rgb(247, 143, 8) 50%,
    rgb(253, 206, 145) 100%
  );
}
.colorbox.colorbox-red {
  background-color: #fb3a3a;
  /* background: linear-gradient(
    180deg,
    rgb(251, 58, 58) 50%,
    rgb(248, 160, 160) 100%
  ); */
}
.colorbox.colorbox-green {
  background: #28c33d;
  /* background: linear-gradient(
    180deg,
    rgb(40, 195, 61) 50%,
    rgb(143, 247, 156) 100%
  ); */
}
.colorbox.colorbox-darkgreen {
  background: #154640;
}
.colorbox.colorbox-blue {
  /* background-color: blue; */
  background: #063c76;
}
.colorbox.colorbox-light-blue {
  /* background-color: blue; */
  background: #3aaffb;
}

.colorbox.colorbox-lightblue {
  /* background-color: lightyellow; */
  background: linear-gradient(
    180deg,
    rgb(125, 192, 250) 50%,
    rgb(225, 239, 252) 100%
  );
}
.colorbox.colorbox-yellow {
  background: #e8e503;
  /* background: linear-gradient(
    180deg,
    rgb(232, 229, 3) 50%,
    rgb(255, 253, 163) 100%
  );
  */
}
.colorbox.colorbox-purple {
  /* background-color: purple; */
  background: linear-gradient(
    180deg,
    rgb(139, 8, 247) 50%,
    rgb(206, 156, 247) 100%
  );
}
.colorbox.colorbox-grey {
  background: #8a8a90;
}
.colorbox.colorbox-black {
  background: #000000;
}
body
  .p-datatable
  .p-datatable-scrollable-wrapper
  .p-datatable-scrollable-header {
  background-color: lightgray;
}
.businessLogoUpload {
  float: right;
}
.p-autocomplete-item {
  padding: 0.5rem;
}
body .p-checkbox .p-checkbox-box:hover {
  cursor: pointer;
  background-color: #e45120;
}
.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}
body .react-resizable:hover {
  box-shadow: 0 0 10px green;
}
.SortableList {
  position: relative;
  z-index: 0;
  background-color: #f3f3f3;
  border: 1px solid #efefef;
  border-radius: 3px;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #e0e0e0;
  list-style: none;
  padding: 0;
}
.SortableItem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  font-weight: 500;
}
.SortableHelper {
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2),
    0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  background-color: rgba(221, 36, 36, 0.8);
  cursor: row-resize;
}
.p-inputnumber-buttons-stacked
  .p-inputnumber-button-group
  .p-button.p-inputnumber-button {
  background-color: green;
}
.react-tooltip-absolute-container {
  /* //z-index: 10000000000000000000000; */
}

.p-dialog-mask {
  z-index: 10000000000 !important;
}
body .p-menu.p-menu-overlay {
  z-index: 10000000000 !important;
}
.p-autocomplete-panel {
  z-index: 10000000000 !important;
}
.p-autocomplete {
  z-index: 10000000 !important;
}
body .p-radiobutton .p-radiobutton-box.p-highlight {
  background-color: #085dc4;
}
body .p-tooltip-active {
  z-index: 100000000000;
  color: white;
}

/** PDF  */
.item {
  width: fit-content;
  min-height: 6.5em;
  margin: 1rem 0 0 1rem;
  background-color: #29e;
  color: white;
  border-radius: 0.75em;
  padding: 4%;
  touch-action: none;
  user-select: none;
  transform: translate(0px, 0px);
}

.pageOutline {
  box-shadow: 0 0 5px rgba(95 95 95);
  /* 

  Page dimensions for Portrait 

  A3 page in pixels (300dpi - dots per inch)
  w: 3508
  h: 4961

  A4 page in pixels (300dpi)
  w: 2480
  h: 3508

  A5 page in pixels (300dpi)
  w: 1748
  h: 2480


  A4 Page divided by 4:
    width: 620px;
    height: 877px;
  */
  position: absolute;
  margin-left: 250px;
}

.resize-container {
  position: relative;
  max-width: inherit;
  max-height: inherit;
}

#outer-dropzone {
  height: 140px;
}

#inner-dropzone {
  height: 80px;
}

.dropzone {
  background-color: #bfe4ff;
  border: dashed 4px transparent;
  border-radius: 4px;
  margin: 10px auto 30px;
  padding: 10px;
  width: 80%;
  transition: background-color 0.3s;
}
.dropzone .drop-active {
  border-color: #aaa;
}
.drop-active {
  border-color: #aaa;
}

.drop-target {
  background-color: #29e;
  border-color: #fff;
  border-style: solid;
}

.drag-drop {
  display: inline-block;
  min-width: 40px;
  padding: 2em 0.5em;
  margin: 1rem 0 0 1rem;

  color: #fff;
  background-color: #29e;
  border: solid 2px #fff;

  touch-action: none;
  transform: translate(0px, 0px);

  transition: background-color 0.3s;
}

.drag-drop.can-drop {
  color: #000;
  background-color: #4e4;
}
.can-drop {
  color: #000;
  background-color: #4e4;
}

.drag-item {
  width: fit-content;
  height: fit-content;
  /* min-height: 6.5em; */
  /* margin: 10%; */

  background-color: #29e;
  color: white;

  border-radius: 0.75em;
  padding: 4%;

  -webkit-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.card-categories {
  border-radius: 2px;
  border: 0px solid #00ac86;
  background-color: white;
  box-shadow: 0 0 2px black;
  margin-bottom: 1em;
  -webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
}

.p-password {
  display: inline;
}

.p-tooltip {
  z-index: 9999999999 !important;
}

.capabilityCard {
  text-align: center;
  border: 0.5px solid #00ac86;
  border-radius: 5px;
}
.capabilityTitle {
  background-color: lightgray;
  color: Black;
  border-bottom: 0.5px solid #00ac86;
}
.capabilityLeft {
  margin-top: 3px;
  border-right: 0.5px solid #00ac86;
  overflow: auto;
}
.capabilityRight {
  margin-top: 3px;
  border-left: 0.5px solid #00ac86;
  overflow: auto;
}
.divideRight {
  border-right: 0.5px solid #00ac86;
}

.p-row-expanded .scrl {
  height: auto;
}

.outlook-list {
  /* border: #000000 2px; */
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
  height: 75vh;
  overflow: auto;
}
.outlook-card {
  /* border: #000000 solid 2px; */
  box-shadow: 0 3px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
}
.outlook-card:hover {
  background: #cee3eb;
  color: black;
  /* -webkit-filter: invert(100%); */
}
.outlook-selectedMessage {
  height: fit-content;
  overflow: auto;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
  padding: 10px;
}

.custom-skeleton {
  border: 1px solid var(--surface-d);
  /* border-borderRadius: 4px; */
}
.custom-skeleton ul {
  list-style: none;
}

.p-skeleton {
  position: relative;
  overflow: hidden;
  background-color: #e9ecef;
  border-radius: 3px;
}
.p-mr-2 {
  border: 1px solid var(--surface-d);
  /* border-borderRadius: 4px; */
}
.p-mb-2 {
  margin-bottom: 0.5rem !important;
}

.p-formgrid {
  width: 100%;
}

.p-skeleton-circle {
  border-radius: 50%;
}

.menu-scroll-topbar {
  font-size: 13px;
}

.docImageSidebar {
  z-index: 100000000000 !important;
  padding-left: 0.5em;
}

.docImageSidebar .p-sidebar-content {
  padding-top: 1em;
}

.p-fieldset-toggleable .p-fieldset-legend a {
  overflow: inherit;
}

.href {
  color: blue;
}
.href:hover {
  cursor: pointer;
}

body .p-tabview .p-tabview-nav .pi {
  color: black !important;
}

body .p-tabview .p-tabview-nav {
  border: 1px solid #d8d8d8;
}

.p-dialog-header {
  font-size: 16px;
  margin-left: 20px;
}

.categories-header-row {
  background-color: #00ac86;
  color: #ffffff;
}

.deleteIcon {
  background-color: red !important;
  float: right;
}
